<template>
  <div id="footer" class="mt-3">
    <div class="container-lg">
      <footer class="row align-items-center">
        <div class="col-12 py-4 text-center">
          <p
            class="text-muted small m-0 fw-light"
          >


            Hak Cipta Terpelihara © 2022 Lembaga Tabung Haji

            <br>
            <br>



            <span style="font-size: 10px;">Hak cipta portal e-Bimbingan Haji Lembaga Tabung Haji dan kandungannya yang termasuk maklumat, teks, imej, grafik, logo dan susunannya, dan bahan-bahan di dalamnya dimiliki oleh <strong style="font-weight: 900;"><i>TH</i></strong> melainkan dinyatakan sebaliknya. Tiada bahagian atau bahagian laman e-Bimbingan Haji Lembaga Tabung Haji ini boleh diubahsuai, disalin, diedarkan, dihantar semula, disiarkan, dipaparkan, diterbitkan, dilesenkan, dipindahkan, dijual atau dijual secara komersil dengan apa-apa cara tanpa mendapat kebenaran secara bertulis yang jelas terlebih dahulu daripada <strong style="font-weight: 900;"><i>TH</i></strong>.</span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/color.scss";

.alignment-text {
  &.start {
    text-align: left;

    @media (max-width: 420px) {
      text-align: center;
    }
  }
  &.end {
    text-align: right;
    color: rgb(181, 182, 198) !important;

    @media (max-width: 420px) {
      text-align: center;
    }
  }
}

#footer {
  background-color: $white;

  footer {
    small {
      color: $gray-light;
    }
    a {
      color: $gray-reqular;
    }
  }
}
</style>